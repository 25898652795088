import { useCallback, useState } from 'react';

interface ReturnType {
  isToggled: boolean;
  toggle: () => void;
  toggleOn: () => void;
  toggleOff: () => void;
}

export const useToggle = (initial = false): ReturnType => {
  const [isToggled, setIsToggled] = useState(initial);

  const toggle = useCallback(() => setIsToggled((prev) => !prev), []);
  const toggleOn = useCallback(() => setIsToggled(true), []);
  const toggleOff = useCallback(() => setIsToggled(false), []);

  return {
    isToggled,
    toggle,
    toggleOn,
    toggleOff,
  };
};
