import { DefaultHeaders } from './types';

export const getDefaultHeaders = (
  cmsSubdomain: string,
  token: string,
): DefaultHeaders => {
  const headers = {
    repository: cmsSubdomain,
    Authorization: `Bearer ${token}`,
  };

  return headers as DefaultHeaders;
};
