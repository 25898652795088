import { createContext, FC, ReactNode, useContext } from 'react';

import { useToggle } from './useToggle';

interface ProviderProps {
  children: ReactNode;
}

export const DrawerProvider: FC<ProviderProps> = ({ children }) => {
  const { isToggled, toggleOn, toggleOff } = useToggle();

  return (
    <DrawerContext.Provider
      value={{
        isOpen: isToggled,
        showDrawer: toggleOn,
        hideDrawer: toggleOff,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export interface DrawerContextProps {
  isOpen: boolean;
  showDrawer: () => void;
  hideDrawer: () => void;
}

const DrawerContext = createContext<DrawerContextProps | undefined>(undefined);

export const useDrawer = (): DrawerContextProps => {
  const context = useContext(DrawerContext);

  if (context === undefined) {
    throw new Error('useDrawer must be used within an DrawerProvider');
  }

  return context;
};
