import {
  QueryFunction,
  QueryKey,
  SkipToken,
  UndefinedInitialDataOptions,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { handleErrors } from './errors';

export const useApiQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
>(
  queryFn: QueryFunction<TQueryFnData, QueryKey> | SkipToken,
  options?: UndefinedInitialDataOptions<unknown, TError, TData, QueryKey>,
): UseQueryResult<TData, TError> => {
  const { t } = useTranslation();

  return useQuery({
    ...options,
    queryKey: options?.queryKey ?? [],
    queryFn: (...a) =>
      handleErrors(
        () => (typeof queryFn === 'function' ? queryFn(...a) : null),
        t,
      ),
  });
};
