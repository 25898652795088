import axios, { InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { stringify } from '@lib/route/queryParams';
import { COOKIE_STORAGE } from '@lib/storage/constants';
import Userfront from '@userfront/nextjs';

export const client = axios.create({
  paramsSerializer: (params) => stringify(params),
});

client.interceptors.request.use(mutateRequestConfig, (error) => {
  Promise.reject(error);
});

function mutateRequestConfig(
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig {
  const token = Userfront.accessToken();

  if (!config.headers) {
    config.headers = new axios.AxiosHeaders();
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const xsrfToken = Cookies.get(COOKIE_STORAGE.XSRF);
  if (xsrfToken) {
    config.headers['X-XSRF-TOKEN'] = xsrfToken;
  }

  return config;
}
