import { UserRole } from '@api/userfront/auth/types';
import { getEnv } from '@lib/env/getEnv';
import { QueryParams } from '@lib/route/types';
import { Tenant } from '@lib/tenants/types';

import { findRoute } from '../route/checkRoute';
import { APP_ROUTES } from '../route/constants';
import { AuthorizationResponse } from './types';

export const verifyAuthority = (
  tenant: Tenant | undefined,
  isAuthenticated: boolean,
  asPath: string,
  query: QueryParams,
  userRoles?: UserRole[]
): AuthorizationResponse => {
  const route = findRoute(APP_ROUTES, 'path', asPath, query);
  const tenantName = tenant?.config.setup.name;
  const allowedTenants = route?.allowedTenants;
  const allowedRoles = route?.allowedRoles;
  const allowedEnvironments = route?.allowedEnvironments;
  const env = getEnv();
  let redirectTo: string | null = null;

  if (!route || !tenantName) {
    return {
      isAuthorized: true,
      redirectTo: null,
    };
  }

  const isBadTenant = !!allowedTenants && !allowedTenants?.includes(tenantName);
  const isBadEnvironment =
    !!allowedEnvironments && !allowedEnvironments.includes(env.client.appEnv);

  if (isBadTenant || isBadEnvironment) {
    redirectTo = APP_ROUTES.HOME.path;
  }

  if (isAuthenticated) {
    const hasRole = allowedRoles?.some((allowedRole) =>
      userRoles?.includes(allowedRole)
    );
    const isMissingRequiredRole = !!allowedRoles && !hasRole;

    if (route.isGuestOnly || isMissingRequiredRole) {
      redirectTo = APP_ROUTES.HOME.path;
    }
  } else if (!isAuthenticated && route.isAuthOnly) {
    redirectTo = APP_ROUTES.AUTH.LOGIN.path;
  }

  return {
    isAuthorized: !redirectTo,
    redirectTo,
  };
};
