import { Tenant, TenantName } from '../types';
import { BASE_CONFIG } from './base/constants';
import { C_MOBILE_CONFIG } from './c-mobile/constants';
import { FOOTBALLERISTA_CONFIG } from './footballerista/constants';
import { STAGING_CONFIG } from './staging/constants';
import { KIOSK_BESTCONNECT_CONFIG } from './kiosk-bestconnect/constants';
import { TELFI_CONFIG } from './telfi/constants';
import { L2_TRAVEL_SIM_CONFIG } from './l2-travel-sim/constants';
import { FAIRPLAY_MOBILE_CONFIG } from '@lib/tenants/config/fairplay-mobile/constants';
import { WORLD_MOBILE_CONFIG } from '@lib/tenants/config/world-mobile/constants';

export const TENANT_CONFIG: Record<TenantName, Tenant> = {
  base: BASE_CONFIG,
  'c-mobile': C_MOBILE_CONFIG,
  staging: STAGING_CONFIG,
  footballerista: FOOTBALLERISTA_CONFIG,
  'kiosk-bestconnect': KIOSK_BESTCONNECT_CONFIG,
  telfi: TELFI_CONFIG,
  'l2-travel-sim': L2_TRAVEL_SIM_CONFIG,
  'fairplay-mobile': FAIRPLAY_MOBILE_CONFIG,
  'world-mobile': WORLD_MOBILE_CONFIG,
};
