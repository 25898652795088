import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import { TenantPaymentCapabilities, TenantResult } from './types';
import { replaceRouteParams } from '@lib/route/routeParams';

export const getTenantById = async (
  tenantId: string,
  tenant: Tenant,
): Promise<TenantResult> => {
  const res = await client.get<TenantResult>(API_URLS.tenant.getTenantById, {
    baseURL: getBaseUrl(tenant),
    params: {
      tenantId,
    },
  });

  return res.data;
};

export const getTenantPaymentCapabilitiesForUnauthenticatedUser = async (
  tenantId: string,
  tenant: Tenant,
): Promise<TenantPaymentCapabilities[]> => {
  const res = await client.get<TenantPaymentCapabilities[]>(
    replaceRouteParams(
      API_URLS.tenant.getTenantPaymentCapabilitiesForUnauthenticatedUser,
      { tenantId },
    ),
    {
      baseURL: getBaseUrl(tenant),
    },
  );
  return res.data;
};

export const getTenantPaymentCapabilities = async (
  tenant: Tenant,
): Promise<TenantPaymentCapabilities[]> => {
  const res = await client.get<TenantPaymentCapabilities[]>(
    API_URLS.tenant.getTenantPaymentCapabilities,
    {
      baseURL: getBaseUrl(tenant),
    },
  );
  return res.data;
};
