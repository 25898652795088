import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import { Classifier } from './types';

export const getAll = async (tenant: Tenant): Promise<Classifier[]> => {
  const res = await client.get<Classifier[]>(API_URLS.classifiers.index, {
    baseURL: getBaseUrl(tenant),
  });

  return res.data;
};
