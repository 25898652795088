import { is, mergeWith } from 'ramda';
import { fontWeightSizes } from './themeSetup';

export function deepMerge<
  T extends Record<string, any>,
  U extends Record<string, any>,
>(defaultObj: T, updatedObj?: U): T {
  return is(Object, defaultObj) && is(Object, updatedObj)
    ? mergeWith(deepMerge, defaultObj, updatedObj)
    : updatedObj;
}

export function createResponsiveFontSizes(lg: number, md: number, sm: number) {
  return {
    fontSize: lg,
    '@media (max-width:960px)': {
      fontSize: md,
    },
    '@media (max-width:600px)': {
      fontSize: sm,
    },
  };
}

export function createHeadingStyle(
  fontSizeLg: number,
  fontSizeMd: number,
  fontSizeSm: number,
  lineHeight: React.CSSProperties['lineHeight'],
  fontWeight: React.CSSProperties['fontWeight'] = fontWeightSizes.fontWeightMedium,
) {
  return {
    lineHeight,
    fontWeight,
    margin: 0,
    padding: 0,
    color: 'inherit',
    ...createResponsiveFontSizes(fontSizeLg, fontSizeMd, fontSizeSm),
  };
}

export function createTextStyle(
  fontSizeLg: number,
  fontSizeMd: number | undefined,
  fontSizeSm: number | undefined,
  lineHeight: React.CSSProperties['lineHeight'],
) {
  return {
    lineHeight,
    ...(!fontSizeMd || !fontSizeSm
      ? { fontSize: fontSizeLg }
      : createResponsiveFontSizes(fontSizeLg, fontSizeMd, fontSizeSm)),
  };
}
