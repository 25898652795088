import { authConfig } from '@api/internal/config';
import { API_URLS } from '@api/internal/constants';
import { withQueryParams } from '@lib/route/queryParams';
import { replaceRouteParams } from '@lib/route/routeParams';
import { Tenant } from '@lib/tenants/types';

import { client } from '../../client';
import {
  DeleteSingleQueryParams,
  DeleteSingleRo,
  DeleteSingleRouteParams,
  GetAllQueryParams,
  GetAllRo,
  GetSingleQueryParams,
  GetSingleRo,
  GetSingleRouteParams,
  UpdateSingleDto,
  UpdateSingleQueryParams,
  UpdateSingleRo,
  UpdateSingleRouteParams,
} from './types';

export const getAll = async (
  tenant: Tenant,
  queryParams: GetAllQueryParams
): Promise<GetAllRo> => {
  const res = await client.get<GetAllRo>(
    withQueryParams(API_URLS.prismic.customTypes, queryParams),
    authConfig(tenant.config.setup.name)
  );

  return res.data;
};

export const getSingle = async (
  tenant: Tenant,
  routeParams: GetSingleRouteParams,
  queryParams: GetSingleQueryParams
): Promise<GetSingleRo> => {
  const withRouteParams = replaceRouteParams(
    API_URLS.prismic.customType,
    routeParams
  );
  const res = await client.get<GetSingleRo>(
    withQueryParams(withRouteParams, queryParams),
    authConfig(tenant.config.setup.name)
  );

  return res.data;
};

export const deleteSingle = async (
  tenant: Tenant,
  routeParams: DeleteSingleRouteParams,
  queryParams: DeleteSingleQueryParams
): Promise<DeleteSingleRo> => {
  const withRouteParams = replaceRouteParams(
    API_URLS.prismic.customType,
    routeParams
  );
  const res = await client.delete<DeleteSingleRo>(
    withQueryParams(withRouteParams, queryParams),
    authConfig(tenant.config.setup.name)
  );

  return res.data;
};

export const updateSingle = async (
  tenant: Tenant,
  routeParams: UpdateSingleRouteParams,
  queryParams: UpdateSingleQueryParams,
  dto: UpdateSingleDto
): Promise<UpdateSingleRo> => {
  const withRouteParams = replaceRouteParams(
    API_URLS.prismic.customType,
    routeParams
  );
  const res = await client.post<UpdateSingleRo>(
    withQueryParams(withRouteParams, queryParams),
    dto,
    authConfig(tenant.config.setup.name)
  );

  return res.data;
};
