import { createContext, FC, ReactNode, useContext, useState } from 'react';

import {
  ColorPaletteDoc,
  FiltersDoc,
  FooterMenuDoc,
  InventoryTypeVariantsConfigDoc,
  MediaAssetsDoc,
  NavigationMenuDoc,
} from '@api/prismic/components/types';
import { Language } from '@prismicio/client/types/ResolvedApi';

import { Tenant } from './types';

interface ProviderProps {
  children: ReactNode;
  initTenant: Tenant;
  initCmsLanguages: Language[];
  initNavigationMenu: NavigationMenuDoc['data'] | null;
  initFooterMenu: FooterMenuDoc['data'] | null;
  initFilters: FiltersDoc['data'] | null;
  initColorPalette: ColorPaletteDoc['data'] | null;
  initInventoryTypeVariantsConfig:
    | InventoryTypeVariantsConfigDoc['data']
    | null;
  initMediaAssets: MediaAssetsDoc['data'] | null;
}

export const TenantPropsProvider: FC<ProviderProps> = ({
  children,
  initTenant,
  initCmsLanguages,
  initNavigationMenu,
  initFooterMenu,
  initFilters,
  initColorPalette,
  initInventoryTypeVariantsConfig,
  initMediaAssets,
}) => {
  const [tenant] = useState<ProviderProps['initTenant']>(initTenant);
  const [cmsLanguages] =
    useState<ProviderProps['initCmsLanguages']>(initCmsLanguages);
  const [navigationMenu] =
    useState<ProviderProps['initNavigationMenu']>(initNavigationMenu);
  const [footerMenu] =
    useState<ProviderProps['initFooterMenu']>(initFooterMenu);
  const [filters] = useState<ProviderProps['initFilters']>(initFilters);
  const [colorPalette] =
    useState<ProviderProps['initColorPalette']>(initColorPalette);
  const [inventoryTypeVariantsConfig] = useState<
    ProviderProps['initInventoryTypeVariantsConfig']
  >(initInventoryTypeVariantsConfig);
  const [mediaAssets] =
    useState<ProviderProps['initMediaAssets']>(initMediaAssets);

  return (
    <TenantPropsContext.Provider
      value={{
        tenant,
        cmsLanguages,
        navigationMenu,
        footerMenu,
        filters,
        colorPalette,
        inventoryTypeVariantsConfig,
        mediaAssets,
      }}
    >
      {children}
    </TenantPropsContext.Provider>
  );
};

export interface TenantPropsContextProps<
  WithExportPossibility extends boolean,
> {
  tenant: WithExportPossibility extends true ? Tenant | undefined : Tenant;
  cmsLanguages: Language[];
  navigationMenu: NavigationMenuDoc['data'] | null;
  footerMenu: FooterMenuDoc['data'] | null;
  filters: FiltersDoc['data'] | null;
  colorPalette: ColorPaletteDoc['data'] | null;
  inventoryTypeVariantsConfig: InventoryTypeVariantsConfigDoc['data'] | null;
  mediaAssets: MediaAssetsDoc['data'] | null;
}

const TenantPropsContext = createContext<
  TenantPropsContextProps<true | false> | undefined
>(undefined);

export const useTenantProps = <
  WithExportPossibility extends boolean = false,
>(): TenantPropsContextProps<WithExportPossibility> => {
  const context = useContext(TenantPropsContext);

  if (context === undefined) {
    throw new Error(
      'useTenantProps must be used within an TenantPropsProvider',
    );
  }

  return context;
};
