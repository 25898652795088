import { AvailableLocales } from '@lib/route/types';
import { enGB, es, tr, ar, it, fr, zhCN } from 'date-fns/locale';
import { formatISO } from 'date-fns';

export const DEFAULT_DATE_FNS_LOCALE = enGB;

export const NEXTJS_LOCALE_TO_DATE_FNS: Record<AvailableLocales, Locale> = {
  'en-gb': enGB,
  'es-es': es,
  tr: tr,
  'it-it': it,
  'fr-fr': fr,
  'ar-ly': ar,
  'ar-ae': ar,
  'zh-cn': zhCN,
};

export const TODAY_ISO_DATE = formatISO(new Date(), { representation: 'date' });
