import { replaceRouteParams } from '@lib/route/routeParams';
import { Tenant } from '@lib/tenants/types';

import { SuccessResponse } from '../auth/types';
import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import { Invoice, InvoiceListItem, Price } from './types';

export const list = async (
  tenant: Tenant,
  accountId: string
): Promise<InvoiceListItem[]> => {
  const res = await client.get<InvoiceListItem[]>(API_URLS.invoice.list, {
    baseURL: getBaseUrl(tenant),
    params: { accountId },
  });

  return res.data;
};

export const invoice = async (
  tenant: Tenant,
  invoiceId: string
): Promise<Invoice> => {
  const res = await client.get<Invoice>(API_URLS.invoice.index, {
    baseURL: getBaseUrl(tenant),
    params: { invoiceId },
  });

  return res.data;
};

export const sendInvoiceNotification = async (
  tenant: Tenant,
  invoiceId: string
): Promise<SuccessResponse> => {
  const res = await client.post<SuccessResponse>(
    `${API_URLS.invoice.notify}?invoiceId=${invoiceId}`,
    {},
    {
      baseURL: getBaseUrl(tenant),
    }
  );
  return res.data;
};

export const getAccountUpcomingInvoiceAmount = async (
  tenant: Tenant,
  accountId: string
): Promise<Price> => {
  const res = await client.get<Price>(
    replaceRouteParams(API_URLS.invoice.upcomingAmount, { accountId }),
    {
      baseURL: getBaseUrl(tenant),
      params: { accountId },
    }
  );

  return res.data;
};
