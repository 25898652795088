import { useMemo } from 'react';
import { useEffect, useState } from 'react';

import { isServer, isStorageAvailable } from './availability';
import { StorageType, StorageUnavailable } from './types';

export function useStorage<T>(
  type: StorageType,
  key: string,
  initialValue: T
): [T | StorageUnavailable, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T | StorageUnavailable>(
    'UNAVAILABLE'
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoInitialValue = useMemo(() => initialValue, []);

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);

      if (!isServer && isStorageAvailable()) {
        const STORAGE_TYPE_MAP: Record<StorageType, Storage> = {
          local: localStorage,
          session: sessionStorage,
        };
        STORAGE_TYPE_MAP[type].setItem(key, JSON.stringify(valueToStore));
      }
    } catch {}
  };

  // Load real value after mount, since storage won't be
  // available inside the server. (client-server content mismatch)
  useEffect(() => {
    setStoredValue(() => {
      try {
        if (!isStorageAvailable()) {
          return 'UNAVAILABLE';
        }

        const STORAGE_TYPE_MAP: Record<StorageType, Storage> = {
          local: localStorage,
          session: sessionStorage,
        };

        const item = STORAGE_TYPE_MAP[type].getItem(key);

        return item ? JSON.parse(item) : memoInitialValue;
      } catch (error) {
        return memoInitialValue;
      }
    });
  }, [memoInitialValue, key, type]);

  return [storedValue, setValue];
}
