import { withQueryParams } from '@lib/route/queryParams';
import { replaceRouteParams } from '@lib/route/routeParams';
import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import {
  GetBalanceRo,
  GetBalanceRouteParams,
  GetCdrQueryParams,
  GetCdrRo,
  GetCdrRouteParams,
  GetSubscriptionsRo,
  GetSubscriptionsRouteParams,
  Subscription,
} from './types';

export const getSubscriptions = async (
  tenant: Tenant,
): Promise<GetSubscriptionsRo> => {
  const { data } = await client.get<GetSubscriptionsRo>(
    API_URLS.subscription.index,
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return data;
};

export const getSubscription = async (
  routeParams: GetSubscriptionsRouteParams,
  tenant: Tenant,
): Promise<Subscription> => {
  const { data } = await client.get<Subscription>(
    replaceRouteParams(API_URLS.subscription.subscription, routeParams),
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return data;
};

export const getCdr = async (
  routeParams: GetCdrRouteParams,
  queryParams: GetCdrQueryParams,
  tenant: Tenant,
): Promise<GetCdrRo> => {
  const withRouteParams = replaceRouteParams(
    API_URLS.subscription.cdr,
    routeParams,
  );
  const { data } = await client.get<GetCdrRo>(
    withQueryParams(withRouteParams, queryParams),
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return data;
};

export const getBalance = async (
  routeParams: GetBalanceRouteParams,
  tenant: Tenant,
): Promise<GetBalanceRo> => {
  const { data } = await client.get<GetBalanceRo>(
    replaceRouteParams(API_URLS.subscription.balance, routeParams),
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return data;
};
