import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { GetMobileRatesRo, GetRatesRo, GetRoamingRatesRo } from './types';

export const getRates = async (tenant: Tenant): Promise<GetRatesRo> => {
  const res = await client.get<GetRatesRo>(
    `tenants/${tenant.config.setup.name}/docs/rates.json`
  );

  return res.data;
};

export const getMobileRates = async (
  tenant: Tenant
): Promise<GetMobileRatesRo> => {
  const res = await client.get<GetMobileRatesRo>(
    `tenants/${tenant.config.setup.name}/docs/mobile-rates.json`
  );

  return res.data;
};

export const getRoamingRates = async (
  tenant: Tenant
): Promise<GetRoamingRatesRo> => {
  const res = await client.get<GetRoamingRatesRo>(
    `tenants/${tenant.config.setup.name}/docs/roaming-rates.json`
  );

  return res.data;
};
