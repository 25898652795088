import { APP_ROUTES } from '@lib/route/constants';
import { Tenant } from '@lib/tenants/types';

export const STAGING_CONFIG: Tenant = {
  config: {
    setup: {
      id: {
        local: '1',
        demo: '1',
        qa: undefined,
        prod: undefined,
        staging: '1',
      },
      name: 'staging',
      cmsSubdomain: {
        local: 'ocb-base-staging',
        demo: 'ocb-base-staging',
        staging: 'ocb-base-staging',
        qa: undefined,
        prod: undefined,
      },
      ryftPublicKey: {
        local:
          'pk_sandbox_bf8UkQjIuFRIdOAQBWZJFd37BO8Kn6rSnERvMoiEALnb+aXsIy7Ep6pzumNNCOS/',
        demo: 'pk_sandbox_bf8UkQjIuFRIdOAQBWZJFd37BO8Kn6rSnERvMoiEALnb+aXsIy7Ep6pzumNNCOS/',
        qa: 'pk_sandbox_bf8UkQjIuFRIdOAQBWZJFd37BO8Kn6rSnERvMoiEALnb+aXsIy7Ep6pzumNNCOS/',
        prod: 'pk_sandbox_bf8UkQjIuFRIdOAQBWZJFd37BO8Kn6rSnERvMoiEALnb+aXsIy7Ep6pzumNNCOS/',
        staging:
          'pk_sandbox_bf8UkQjIuFRIdOAQBWZJFd37BO8Kn6rSnERvMoiEALnb+aXsIy7Ep6pzumNNCOS/',
      },
      paypalPublicKey: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: undefined,
        staging:
          'AUXG7rcYCgh0FdnAkjsqLHpMXUY3bC73lZlcC0M-l4nVhGcCypXpRzwIOaP4rwoUoVy0vomHnzSTjkYA',
      },
      authTenantId: {
        local: 'vbqx6g8n',
        demo: 'vbqx6g8n',
        staging: 'vbqx6g8n',
        qa: undefined,
        prod: undefined,
      },
      ocbDigitalBaseUrl: {
        demo: 'https://digital-staging.opencloudbss.com/digital/api',
        qa: undefined,
        prod: undefined,
        staging: 'https://digital-staging.opencloudbss.com/digital/api',
      },
    },
  },
  cmsRoutes: [
    {
      type: 'page',
      path: APP_ROUTES.ROOT_UID.path,
    },
    {
      type: 'home-page',
      path: APP_ROUTES.HOME.path,
    },
    {
      type: 'privacy-policy',
      path: APP_ROUTES.PRIVACY_POLICY.path,
    },
    {
      type: 'available_products',
      path: APP_ROUTES.PLANS.path,
    },
    {
      type: 'terms-and-conditions',
      path: APP_ROUTES.TERMS_AND_CONDITIONS.path,
    },
  ],
  theme: {},
  seo: {
    displayName: 'Metasite Base',
  },
};
