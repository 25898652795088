import getConfig from 'next/config';

import { TenantName } from '@lib/tenants/types';

import { parseStringified } from './parseStringified';
import {
  ClientTenantSecret,
  EnvBasedTenantSecret,
  TenantSecret,
  TypedEnv,
} from './types';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export const getEnv = (): TypedEnv => ({
  client: {
    appEnv: publicRuntimeConfig?.appEnv || 'local',
    proxyPrefix: process.env.NEXT_PUBLIC_PROXY_PREFIX,
    debug: !!process.env.NEXT_PUBLIC_DEBUG,
    clientTenantSecrets:
      parseStringified<Record<TenantName, ClientTenantSecret>>(
        publicRuntimeConfig?.clientTenantSecrets
      ) ??
      parseStringified<Record<TenantName, ClientTenantSecret>>(
        process.env.NEXT_PUBLIC_CLIENT_TENANT_SECRETS
      ),
  },
  server: {
    envBasedTenantSecrets: parseStringified<EnvBasedTenantSecret>(
      process.env.ENV_BASED_TENANT_SECRETS
    ),
    tenantSecrets: parseStringified<Record<TenantName, TenantSecret>>(
      serverRuntimeConfig?.tenantSecrets
    ),
    port: process.env.PORT || '443',
    nodeEnv: process.env.NODE_ENV || 'development',
  },
});
