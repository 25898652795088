const DEFAULT_LOCALE = 'en-gb';
const AVAILABLE_LOCALES = [
  'en-gb',
  'es-es',
  'tr',
  'it-it',
  'fr-fr',
  'ar-ae',
  'zh-cn',
  'ar-ly',
];

module.exports = {
  DEFAULT_LOCALE,
  AVAILABLE_LOCALES,
  i18n: {
    defaultLocale: DEFAULT_LOCALE,
    locales: AVAILABLE_LOCALES,
    lowerCaseLng: true,
  },
};
