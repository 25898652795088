export const LOCAL_STORAGE = {};

export const SESSION_STORAGE = {
  SELECTED_SUBSCRIPTION_ID: 'selectedSubscriptionId',
};

export const COOKIE_STORAGE = {
  NEXT_LOCALE: 'NEXT_LOCALE',
  COOKIE_CONSENT: 'COOKIE_CONSENT',
  A2HS_DISMISSED: 'A2HS_DISMISSED',
  XSRF: 'XSRF-TOKEN',
  ROLE: 'USER_ROLE',
};
