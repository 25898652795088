export const API_URLS = {
  customTypes: {
    index: '/customtypes',
    customType: `/customtypes/:id`,
    insert: '/customtypes/insert',
    update: '/customtypes/update',
  },
  slices: {
    index: '/slices',
    slice: `/slices/:id`,
    insert: '/slices/insert',
    update: '/slices/update',
  },
} as const;
