import { api } from '@api/index';
import { cachedResponse } from '@lib/fetch/cache';
import { captureError } from '@lib/fetch/errors';
import { AvailableLocales } from '@lib/route/types';

import { ValidatedLocales } from '../auth/types';
import { CountryCode, Tenant } from './types';

export const tenantLocales = async (
  tenant: Tenant,
  locale: AvailableLocales,
): Promise<ValidatedLocales> => {
  if (tenant) {
    try {
      const localesKey = `cmsLocales-${tenant.config.setup.name}`;
      const cmsApiBase = await cachedResponse(localesKey, () =>
        api.prismic.base.getInfo(tenant),
      );

      const cmsLanguages = cmsApiBase?.data?.languages;
      const isLocaleCompatibleWithCms = !!cmsLanguages?.find(
        (lang) => lang.id === locale,
      );

      return { cmsLanguages, isLocaleCompatibleWithCms };
    } catch (err) {
      captureError(`CMS error while fetching locales`, err);
    }
  }

  return {
    cmsLanguages: [],
    isLocaleCompatibleWithCms: false,
  };
};

export const parseCountryCodeFromTenant = (
  locale?: string,
): CountryCode | undefined => {
  if (!locale) {
    return;
  }

  return locale.slice(-2) as CountryCode;
};
