import { client } from '@api/prismic/client';
import { AvailableLocales } from '@lib/route/types';
import { Tenant } from '@lib/tenants/types';

import { HomePageRo, PrivacyPolicyRo } from './types';
import { TermsAndConditionsRo } from '../dynamic-pages/types';

export const getHomePage = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<HomePageRo> => {
  const homePageRes = (await client(tenant).getSingle('home-page', {
    lang: appLocale,
    ...(previewRef && { ref: previewRef }),
  })) as HomePageRo;

  return homePageRes;
};

export const getPrivacyPolicyPage = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<PrivacyPolicyRo> => {
  const res = (await client(tenant).getSingle('privacy-policy', {
    lang: appLocale,
    ...(previewRef && { ref: previewRef }),
  })) as PrivacyPolicyRo;

  return res;
};

export const getTermsAndConditionsPage = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<TermsAndConditionsRo> => {
  const res = (await client(tenant).getSingle('terms-and-conditions', {
    lang: appLocale,
    ...(previewRef && { ref: previewRef }),
  })) as TermsAndConditionsRo;

  return res;
};
