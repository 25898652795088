import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import {
  PostAgentRegisterDto,
  PostDealerRegisterDto,
  PostRegisterRo,
} from './types';

export const postRegisterDealer = async (
  dto: PostDealerRegisterDto,
  tenant: Tenant
): Promise<PostRegisterRo> => {
  const { data } = await client.post<PostRegisterRo>(
    API_URLS.dealer.register,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return data;
};

export const postRegisterAgent = async (
  dto: PostAgentRegisterDto,
  tenant: Tenant
): Promise<PostRegisterRo> => {
  const { data } = await client.post<PostRegisterRo>(
    API_URLS.agent.register,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return data;
};
