import { Children, FC } from 'react';

import { Box, BoxProps } from '@mui/system';

import { IterableChildren } from './types';

interface Props<T = BoxProps> {
  children: IterableChildren;
  Wrapper?: FC<T>;
  wrapperProps?: Omit<T, 'children'>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const ChildrenRenderer = <T extends {} = BoxProps>(
  props: Props<T>
): JSX.Element => {
  const { Wrapper = Box, children, wrapperProps } = props;

  return (
    <Wrapper {...(wrapperProps as T)}>
      {Children.map(children, (child) => child)}
    </Wrapper>
  );
};
