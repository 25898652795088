import { ParentProduct } from '@lib/offers/types';
import { replaceRouteParams } from '@lib/route/routeParams';
import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import {
  GetOfferParentProductsRo,
  GetOfferProductRo,
  GetOfferProductRouteParams,
  GetOfferProductsParams,
  MatchingOfferQueryParams,
  MatchingOfferRequestBody,
} from './types';

export const getCustomerOfferProduct = async (
  tenant: Tenant,
  routeParams: GetOfferProductRouteParams,
): Promise<GetOfferProductRo> => {
  const res = await client.get<GetOfferProductRo>(
    replaceRouteParams(API_URLS.offer.product, routeParams),
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const getOfferProducts = async (
  tenant: Tenant,
  params: GetOfferProductsParams,
): Promise<GetOfferParentProductsRo> => {
  const { tenantId, offerCategoryId, page = 1, pageSize = 50 } = params;

  const res = await client.get<GetOfferParentProductsRo>(
    replaceRouteParams(API_URLS.offer.products, {}),
    {
      baseURL: getBaseUrl(tenant),
      params: {
        tenantId,
        page,
        pageSize,
        ...(offerCategoryId && { offerCategoryId }),
      },
    },
  );

  return res.data;
};

export const getOfferProductsForExistingCustomer = async (
  tenant: Tenant,
  page: number = 1,
  pageSize: number = 50,
): Promise<GetOfferParentProductsRo> => {
  const res = await client.get<GetOfferParentProductsRo>(
    replaceRouteParams(API_URLS.offer.productsForExistingCustomer, {}),
    {
      baseURL: getBaseUrl(tenant),
      params: {
        page,
        pageSize,
      },
    },
  );

  return res.data;
};

export const getProductsForAddonsFromGivenOffer = async (
  tenant: Tenant,
  offerId: string,
  productId: string,
): Promise<ParentProduct[]> => {
  const res = await client.get<ParentProduct[]>(
    replaceRouteParams(API_URLS.offer.productsForAddons, {
      productId,
      offerId,
    }),
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};

export const getMatchingOffersList = async (
  tenant: Tenant,
  queryParams: MatchingOfferQueryParams = { page: 1, pageSize: 50 },
  dto: MatchingOfferRequestBody,
): Promise<GetOfferParentProductsRo> => {
  const res = await client.post<GetOfferParentProductsRo>(
    API_URLS.offer.matchingOffers,
    dto,
    {
      baseURL: getBaseUrl(tenant),
      params: queryParams,
    },
  );

  return res.data;
};
