import cache from 'memory-cache';

export const cachedResponse = async <T extends unknown>(
  key: string,
  callback: () => Promise<T>,
  previewRef?: string,
  timeInMs = 3600000,
): Promise<T> => {
  if (!previewRef && process.env.NODE_ENV !== 'development') {
    const response = cache.get(key);

    if (!response) {
      const data = await callback();
      cache.put(key, data, timeInMs);

      return data;
    }

    return response;
  }

  const res = await callback();

  return res;
};
