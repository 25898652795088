import { APP_ROUTES } from '@lib/route/constants';
import { Tenant } from '@lib/tenants/types';

export const C_MOBILE_CONFIG: Tenant = {
  config: {
    setup: {
      id: {
        local: '1',
        demo: '1',
        qa: '2',
        prod: '1',
        staging: undefined,
      },
      name: 'c-mobile',
      cmsSubdomain: {
        local: 'c-mobile-local',
        demo: 'c-mobile-demo',
        qa: 'c-mobile-qa',
        prod: 'c-mobile',
        staging: undefined,
      },
      ryftPublicKey: {
        local:
          'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        demo: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        qa: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        prod: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        staging: undefined,
      },
      paypalPublicKey: {
        local:
          'AUXG7rcYCgh0FdnAkjsqLHpMXUY3bC73lZlcC0M-l4nVhGcCypXpRzwIOaP4rwoUoVy0vomHnzSTjkYA',
        demo: 'AUXG7rcYCgh0FdnAkjsqLHpMXUY3bC73lZlcC0M-l4nVhGcCypXpRzwIOaP4rwoUoVy0vomHnzSTjkYA',
        qa: undefined,
        prod: undefined,
        staging: undefined,
      },
      authTenantId: {
        local: 'vbqg47bq',
        demo: 'vbqg47bq',
        qa: 'vndwdvb7',
        prod: '5nxr6mn7',
        staging: undefined,
      },
      ocbDigitalBaseUrl: {
        demo: 'https://c-mobile-demo.opencloudbss.com/digital/api',
        qa: 'https://qa.c-mobile.co.za/digital/api',
        prod: 'https://portal.c-mobile.co.za/digital/api',
        staging: undefined,
      },
      externalHomePage: {
        prod: 'https://c-mobile.co.za/',
      },
    },
  },
  cmsRoutes: [
    {
      type: 'page',
      path: APP_ROUTES.ROOT_UID.path,
    },
    {
      type: 'home-page',
      path: APP_ROUTES.HOME.path,
    },
    {
      type: 'privacy-policy',
      path: APP_ROUTES.PRIVACY_POLICY.path,
    },
    {
      type: 'available_products',
      path: APP_ROUTES.PLANS.path,
    },
    {
      type: 'terms-and-conditions',
      path: APP_ROUTES.TERMS_AND_CONDITIONS.path,
    },
  ],
  theme: {},
  seo: {
    displayName: 'C-Mobile',
  },
};
