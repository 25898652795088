import * as auth from './auth/api';
import * as classifiers from './classifiers/api';
import * as customer from './customer/api';
import * as dealer from './dealer/api';
import * as invoice from './invoice/api';
import * as offer from './offer/api';
import * as order from './order/api';
import * as orderAuthless from './order-authless/api';
import * as sim from './sim/api';
import * as subscription from './subscription/api';
import * as tenant from './tenant/api';
import * as ticket from './ticket/api';
import * as ticketCategory from './ticket-category/api';
import * as user from './user/api';
import * as inventory from './inventory/api';
import * as payment from './payment/api';
import * as paymentAuthless from './payment-authless/api';

export const ocbDigital = {
  classifiers,
  order,
  orderAuthless,
  offer,
  customer,
  sim,
  subscription,
  ticket,
  ticketCategory,
  dealer,
  auth,
  user,
  invoice,
  inventory,
  tenant,
  payment,
  paymentAuthless,
};
