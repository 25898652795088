import { AnySlice } from '@lib/custom-types/types';
import { replaceRouteParams } from '@lib/route/routeParams';

import { client } from '../client';
import { getDefaultHeaders } from '../config';
import { API_URLS } from '../constants';
import { AnySliceRo, AnySlicesRo } from './types';

export const getAll = async (
  cmsSubdomain: string,
  token: string
): Promise<AnySlicesRo> => {
  const res = await client.get<AnySlicesRo>(API_URLS.slices.index, {
    headers: getDefaultHeaders(cmsSubdomain, token),
  });

  return res.data;
};

export const getSingle = async (
  cmsSubdomain: string,
  token: string,
  id: string
): Promise<AnySliceRo> => {
  const res = await client.get<AnySliceRo>(
    replaceRouteParams(API_URLS.slices.slice, { id }),
    {
      headers: getDefaultHeaders(cmsSubdomain, token),
    }
  );

  return res.data;
};

export const insertSingle = async (
  cmsSubdomain: string,
  token: string,
  slice: AnySlice
): Promise<AnySliceRo> => {
  const res = await client.post<AnySliceRo>(API_URLS.slices.insert, slice, {
    headers: {
      ...getDefaultHeaders(cmsSubdomain, token),
      'Content-Type': 'text/plain',
    },
  });

  return res.data;
};

export const updateSingle = async (
  cmsSubdomain: string,
  token: string,
  slice: AnySlice
): Promise<AnySliceRo> => {
  const res = await client.post<AnySliceRo>(API_URLS.slices.update, slice, {
    headers: {
      ...getDefaultHeaders(cmsSubdomain, token),
      'Content-Type': 'text/plain',
    },
  });

  return res.data;
};

export const deleteSingle = async (
  cmsSubdomain: string,
  token: string,
  id: string
): Promise<AnySliceRo> => {
  const res = await client.delete<AnySliceRo>(
    replaceRouteParams(API_URLS.slices.slice, { id }),
    {
      headers: getDefaultHeaders(cmsSubdomain, token),
    }
  );

  return res.data;
};
