import { AvailableLocales } from '@lib/route/types';
import {
  DEFAULT_DATE_FNS_LOCALE,
  NEXTJS_LOCALE_TO_DATE_FNS,
} from '@lib/time/constants';

export const getDateFnsLocale = (
  nextLocale: AvailableLocales | string,
): Locale => {
  if (nextLocale) {
    const dateFnsLocale = NEXTJS_LOCALE_TO_DATE_FNS[nextLocale];
    return dateFnsLocale || DEFAULT_DATE_FNS_LOCALE;
  }

  return DEFAULT_DATE_FNS_LOCALE;
};
