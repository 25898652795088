import { User } from '@api/userfront/auth/types';

import { AccessToken } from './types';

export const getUser = async (
  idToken: string | undefined,
): Promise<User | null> => {
  if (!idToken) {
    return null;
  }

  const decode = (await import('jwt-decode')).default; // Lazy load (client-side decoder)
  const parsedUser: User = decode(idToken);

  return parsedUser;
};

export const getAccessData = async (
  accessToken: string | undefined,
): Promise<AccessToken | null> => {
  if (!accessToken) {
    return null;
  }

  const decode = (await import('jwt-decode')).default; // Lazy load (client-side decoder)
  const parsedAccessData: AccessToken = decode(accessToken);

  return parsedAccessData;
};

export const parseRsaToBase64 = (rsaKey: string): string => {
  return Buffer.from(rsaKey, 'base64').toString();
};

export const serverSideDecode = async (
  accessToken: string,
  rsaKey: string,
): Promise<AccessToken> => {
  const decode = (await import('jwt-simple')).decode; // Lazy load (server-side decoder)

  return decode(
    accessToken,
    Buffer.from(rsaKey, 'base64').toString(),
    false,
    'RS256',
  );
};

export const parseAuthorizationHeader = (
  authHeader: string,
): string | undefined => {
  const prefix = 'Bearer ';

  if (authHeader.startsWith(prefix)) {
    return authHeader.substring(prefix.length, authHeader.length);
  }

  return undefined;
};
