import { replaceRouteParams } from '@lib/route/routeParams';
import { client } from '../client';
import { getBaseUrl } from '../config';
import { Tenant } from '@lib/tenants/types';
import { CapturePaymentRo, CapturePayPalPaymentRouteParams } from './types';
import { API_URLS } from '../constants';

export const capturePayment = async (
  routeParams: CapturePayPalPaymentRouteParams,
  tenant: Tenant,
): Promise<CapturePaymentRo> => {
  const res = await client.post<CapturePaymentRo>(
    replaceRouteParams(API_URLS.payment.capturePayPalPayment, routeParams),
    {},
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return res.data;
};
