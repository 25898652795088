import { getEnv } from '@lib/env/getEnv';
import { Tenant } from '@lib/tenants/types';
import Prismic from '@prismicio/client';
import { ApiOptions } from '@prismicio/client/types/Api';
import { DefaultClient } from '@prismicio/client/types/client';

export const client = (
  tenant: Tenant,
  options: ApiOptions = {}
): DefaultClient => {
  const env = getEnv();

  const cmsSubdomain = tenant.config.setup.cmsSubdomain[env.client.appEnv];

  if (!cmsSubdomain) {
    throw new Error("Tenant doesn't have existing cms subdomain");
  }

  const prismicClient = Prismic.client(
    `https://${cmsSubdomain}.prismic.io/api/v2`,
    createClientOptions(tenant, options)
  );

  return prismicClient;
};

const createClientOptions = (tenant: Tenant, options: ApiOptions = {}) => {
  const env = getEnv();

  const accessToken =
    env.server.tenantSecrets?.[tenant.config.setup.name]?.accessToken;

  return {
    routes: tenant.cmsRoutes,
    ...(accessToken && { accessToken }),
    ...options,
  };
};
