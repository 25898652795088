import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';

import { api } from '@api/index';
import { COOKIE_STORAGE } from '@lib/storage/constants';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import Userfront from '@userfront/nextjs';

import {
  PostLoginDto,
  PostResetPasswordDto,
  PostSignupDto,
  User,
} from '../../api/userfront/auth/types';
import { getUser } from './parser';
import { LogoutParams } from './types';

interface ProviderProps {
  authenticated: boolean;
  children: ReactNode;
}

export const AuthProvider: FC<ProviderProps> = (props) => {
  const { children, authenticated } = props;
  const [isAuthenticated, setAuthenticated] = useState<boolean>(authenticated);
  const [isLoggingIn, setLoggingIn] = useState<boolean>(false);
  const [isLoggingOut, setLoggingOut] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(Userfront.user ?? null);
  const { tenant } = useTenantProps();
  const { t } = useTranslation('auth');

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isAuthenticated,
        isLoggingIn,
        isLoggingOut,
        setAuthenticated,
        onLogin,
        onSignup,
        onResetPassword,
        onLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );

  async function onLogin(dto: PostLoginDto): Promise<User | null> {
    try {
      setLoggingIn(true);

      const res = await api.userfront.auth.postLogin(dto);
      const user = await getUser(res.tokens.id.value);
      const userData = await api.ocbDigital.user.getUser(tenant);
      Cookies.set(COOKIE_STORAGE.ROLE, userData.roles[0], {
        expires: 1,
      });

      setUser(user);
      setAuthenticated(true);

      return user;
    } catch (err) {
      onLogout();
      throw t('login.error.invalidCredentials');
    } finally {
      setLoggingIn(false);
    }
  }

  async function onSignup(dto: PostSignupDto): Promise<User | null> {
    try {
      setLoggingIn(true);

      const res = await api.userfront.auth.postSignup(dto);
      const user = await getUser(res.tokens.id.value);
      await api.ocbDigital.customer.createCustomerUser(
        res.tokens.id.value,
        tenant
      );

      setUser(user);
      setAuthenticated(true);

      return user;
    } catch (err) {
      throw err;
    } finally {
      setLoggingIn(false);
    }
  }

  async function onResetPassword(
    dto: PostResetPasswordDto
  ): Promise<User | null> {
    try {
      setLoggingIn(true);

      const res = await api.userfront.auth.postResetPassword(dto);
      const user = await getUser(res.tokens.id.value);

      setUser(user);
      setAuthenticated(true);

      return user;
    } catch (err) {
      throw err;
    } finally {
      setLoggingIn(false);
    }
  }

  async function onLogout(params?: LogoutParams): Promise<null> {
    try {
      if (!isLoggingOut) {
        setLoggingOut(true);
        Cookies.remove(COOKIE_STORAGE.ROLE);

        await api.userfront.auth.deleteLogout();

        if (params?.onSuccess) {
          params.onSuccess();
        }

        setAuthenticated(false);
      }

      return null;
    } catch (err) {
      throw err;
    } finally {
      setLoggingOut(false);
    }
  }
};

export interface AuthContextProps {
  user: User | null;
  isAuthenticated: boolean;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
  setUser: Dispatch<SetStateAction<User | null>>;
  onLogin: (dto: PostLoginDto) => Promise<User | null>;
  onSignup: (dto: PostSignupDto) => Promise<User | null>;
  onResetPassword: (dto: PostResetPasswordDto) => Promise<User | null>;
  onLogout: (params?: LogoutParams) => Promise<null>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
