import { client } from '../client';
import { API_URLS } from '../constants';
import { PostVerifyTokenDto, PostVerifyTokenRo } from './types';

export const postVerifyToken = async (
  dto: PostVerifyTokenDto
): Promise<PostVerifyTokenRo> => {
  const res = await client.post<PostVerifyTokenRo>(
    API_URLS.auth.verifyToken,
    dto
  );

  return res.data;
};
