import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { authConfig } from '../config';
import { API_URLS } from '../constants';
import { GetClearCacheRo } from './types';

export const getClearCache = async (
  tenant: Tenant
): Promise<GetClearCacheRo> => {
  const res = await client.get<GetClearCacheRo>(
    API_URLS.clearCache.index,
    authConfig(tenant.config.setup.name)
  );

  return res.data;
};
