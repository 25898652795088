import { AxiosRequestConfig } from 'axios';

import { getEnv } from '@lib/env/getEnv';
import { isServer } from '@lib/storage/availability';
import { TenantName } from '@lib/tenants/types';

export const authConfig = (tenantName: TenantName): AxiosRequestConfig => {
  const config = {
    headers: {
      'tenant-name': tenantName,
    },
  };

  return config;
};

export const getBaseUrl = (): string => {
  const env = getEnv();
  const protocol = env.client.appEnv === 'local' ? 'https' : 'http';
  const host = `${protocol}://localhost:${env.server.port}`;
  const baseUrl = isServer ? host : '';

  return baseUrl;
};
