import { client } from '@api/custom-types/client';
import { Tenant } from '@lib/tenants/types';
import Userfront from '@userfront/nextjs';

import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import { UserResponse } from './types';

export const getUser = async (tenant: Tenant): Promise<UserResponse> => {
  const token = Userfront.accessToken();
  const res = await client.get<UserResponse>(API_URLS.user.index, {
    baseURL: getBaseUrl(tenant),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
