import * as base from './base/api';
import * as components from './components/api';
import * as dynamicPages from './dynamic-pages/api';
import * as staticPages from './static-pages/api';

export const prismic = {
  base,
  components,
  staticPages,
  dynamicPages,
};
