import { APP_ROUTES } from '@lib/route/constants';
import { Tenant } from '@lib/tenants/types';

export const FOOTBALLERISTA_CONFIG: Tenant = {
  config: {
    setup: {
      id: {
        local: '1',
        demo: undefined,
        qa: undefined,
        prod: undefined,
        staging: '1',
      },
      name: 'footballerista',
      cmsSubdomain: {
        local: 'footballerista-local',
        demo: undefined,
        staging: 'footballerista-staging',
        qa: undefined,
        prod: undefined,
      },
      ryftPublicKey: {
        local:
          'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        demo: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        qa: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        prod: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        staging:
          'pk_sandbox_125T+tkTdnQzSl4xQOrzKW4HW/4XeW5kw82SVHDU3NnQ05jJ7p8yyJqHDIrLKlKs',
      },
      paypalPublicKey: {
        local: undefined,
        demo: undefined,
        qa: undefined,
        prod: undefined,
        staging: undefined,
      },
      authTenantId: {
        local: '5nxjmy6n',
        demo: undefined,
        staging: '5nxjmy6n',
        qa: undefined,
        prod: undefined,
      },
      ocbDigitalBaseUrl: {
        demo: undefined,
        qa: undefined,
        prod: undefined,
        staging: 'https://digital-footballerista.opencloudbss.com/digital/api',
      },
    },
  },
  cmsRoutes: [
    {
      type: 'page',
      path: APP_ROUTES.ROOT_UID.path,
    },
    {
      type: 'home-page',
      path: APP_ROUTES.HOME.path,
    },
    {
      type: 'privacy-policy',
      path: APP_ROUTES.PRIVACY_POLICY.path,
    },
    {
      type: 'available_products',
      path: APP_ROUTES.PLANS.path,
    },
    {
      type: 'terms-and-conditions',
      path: APP_ROUTES.TERMS_AND_CONDITIONS.path,
    },
  ],
  theme: {},
  seo: {
    displayName: 'Footballerista',
  },
};
