import { client } from '@api/prismic/client';
import { AvailableLocales } from '@lib/route/types';
import { Tenant } from '@lib/tenants/types';
import Prismic from '@prismicio/client';

import {
  AvailableProductRo,
  AvailableProductsRo,
  ColorPaletteRo,
  FiltersRo,
  FooterMenuRo,
  InventoryTypeVariantsConfigRo,
  MediaAssetsRo,
  NavigationMenuRo,
} from './types';

export const getFooterMenu = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<FooterMenuRo> => {
  const res: FooterMenuRo = await client(tenant).getSingle('footer-menu', {
    lang: appLocale,
    ...(previewRef && { ref: previewRef }),
  });

  return res;
};

export const getFilters = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<FiltersRo> => {
  const res: FiltersRo = await client(tenant).getSingle('filters', {
    lang: appLocale,
    ...(previewRef && { ref: previewRef }),
  });

  return res;
};

export const getHeaderMenu = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<NavigationMenuRo> => {
  const res: NavigationMenuRo = await client(tenant).getSingle(
    'navigation-menu',
    {
      lang: appLocale,
      fetchLinks: [
        'navigation-menu-item.textLink',
        'navigation-menu-item.visibility',
        'navigation-menu-item.disabledForUserRole',
        'navigation-menu-item.slices',
      ],
      ...(previewRef && { ref: previewRef }),
    },
  );

  return res;
};

export const getMediaAssets = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<MediaAssetsRo> => {
  const res: MediaAssetsRo = await client(tenant).getSingle('media_assets', {
    lang: appLocale,
    ...(previewRef && { ref: previewRef }),
  });

  return res;
};

export const getColorPalette = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<ColorPaletteRo> => {
  const res: ColorPaletteRo = await client(tenant).getSingle('color_palette', {
    lang: appLocale,
    ...(previewRef && { ref: previewRef }),
  });

  return res;
};

export const getInventoryTypeVariantsConfig = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<InventoryTypeVariantsConfigRo> => {
  const res: InventoryTypeVariantsConfigRo = await client(tenant).getSingle(
    'inventory_type_variants_configuration',
    {
      lang: appLocale,
      ...(previewRef && { ref: previewRef }),
    },
  );

  return res;
};

export const getAvailableProducts = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<AvailableProductsRo> => {
  const res: AvailableProductsRo = await client(tenant).getSingle(
    'available_products',
    {
      lang: appLocale,
      fetchLinks: [
        'product.mainImage',
        'product.displayTemplate',
        'product.product',
        'product.tagsTitle',
        'product.tags',
        'product.showProduct',
        'product.showBestValueTag',
        'product.showProductPricing',
        'product.showLimitedProductTag',
        'product.showImage',
        'product.defaultSimProduct',
      ],
      ...(previewRef && { ref: previewRef }),
    },
  );

  return res;
};

export const getProductByPrismicId = async (
  prismicDocumentId: string,
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef: string | null,
): Promise<AvailableProductRo> => {
  const res: AvailableProductRo = await client(tenant).queryFirst(
    Prismic.Predicates.at('document.id', prismicDocumentId),
    {
      lang: appLocale,
      ...(previewRef && { ref: previewRef }),
    },
  );

  return res;
};
