import { getEnv } from '@lib/env/getEnv';
import { Tenant } from '@lib/tenants/types';

export const getBaseUrl = (tenant: Tenant): string => {
  const env = getEnv();

  const baseURL =
    env.client.appEnv === 'local'
      ? env.client.proxyPrefix
      : tenant.config.setup.ocbDigitalBaseUrl[env.client.appEnv];

  return baseURL ?? '';
};
