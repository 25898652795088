import { customTypes } from './custom-types';
import { internal } from './internal';
import { ocbDigital } from './ocb-digital';
import { prismic } from './prismic';
import { userfront } from './userfront';

export const api = {
  userfront,
  prismic,
  internal,
  customTypes,
  ocbDigital,
};
