import { API_URLS } from '@api/internal/constants';
import { withQueryParams } from '@lib/route/queryParams';

import { client } from '../../client';
import { GetExitPreviewRo, GetPreviewQueryParams, GetPreviewRo } from './types';

export const getPreview = async (
  queryParams: GetPreviewQueryParams
): Promise<GetPreviewRo> => {
  await client.get<GetPreviewRo>(
    withQueryParams(API_URLS.prismic.preview, queryParams)
  );
};

export const getExitPreview = async (): Promise<GetExitPreviewRo> => {
  await client.get<GetExitPreviewRo>(API_URLS.prismic.exitPreview);
};
