import { Tenant } from '@lib/tenants/types';
import { getBaseUrl } from '../config';
import { client } from '../client';
import { API_URLS } from '../constants';
import { replaceRouteParams } from '@lib/route/routeParams';
import {
  GetInventoryTypeRouteParams,
  InventoryType,
  ResendInventoryQRCodeRo,
  ResendInventoryQRCodeRouteParams,
} from './types';

export const getOrderInventoryType = async (
  routeParams: GetInventoryTypeRouteParams,
  tenant: Tenant,
): Promise<InventoryType> => {
  const { data } = await client.get<InventoryType>(
    replaceRouteParams(API_URLS.inventory.index, routeParams),
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return data;
};

export const resendInventoryQRCode = async (
  tenant: Tenant,
  routeParams: ResendInventoryQRCodeRouteParams,
): Promise<ResendInventoryQRCodeRo> => {
  const { data } = await client.post<ResendInventoryQRCodeRo>(
    replaceRouteParams(API_URLS.inventory.resendInventoryQRCode, routeParams),
    {},
    {
      baseURL: getBaseUrl(tenant),
    },
  );

  return data;
};
