import { getEnv } from '@lib/env/getEnv';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { DEFAULT_DECIMAL_SCALE } from '@lib/price/constants';

export const useDecimalScale = () => {
  const env = getEnv();
  const { tenant } = useTenantProps();

  const tenantDecimalScale =
    tenant.config.setup?.decimalScale &&
    tenant.config.setup?.decimalScale[env.client.appEnv];

  return tenantDecimalScale || DEFAULT_DECIMAL_SCALE;
};
