import { DEFAULT_LOCALE as CONFIG_DEFAULT_LOCALE } from '../../next-i18next.config';
import { AppRoutes, AvailableLocales } from './types';

export const DEFAULT_LOCALE = CONFIG_DEFAULT_LOCALE as AvailableLocales;

export const APP_ROUTES: AppRoutes = {
  HOME: {
    path: `/`,
  },
  ROOT_UID: {
    path: '/:uid',
  },
  BLOG: {
    path: '/blog/:uid',
  },
  BLOGS: {
    path: '/blog/',
  },
  AUTH: {
    LOGIN: {
      path: `/auth/login`,
      isGuestOnly: true,
    },
    LOGOUT: {
      path: `/auth/logout`,
      isAuthOnly: true,
    },
    REGISTER_MSISDN: {
      path: '/auth/register-msisdn',
      isGuestOnly: true,
    },
    SIGNUP: {
      CUSTOMER: {
        path: `/auth/signup/customer`,
        isGuestOnly: true,
      },
      DEALER: {
        path: `/auth/signup/dealer`,
        isGuestOnly: true,
      },
      AGENT: {
        path: `/auth/signup/dealer/agent`,
        isGuestOnly: true,
      },
    },
    FORGOT_PASSWORD: {
      path: `/auth/forgot-password`,
      isGuestOnly: true,
    },
    RESET_PASSWORD: {
      path: `/auth/reset-password`,
      isGuestOnly: true,
    },
  },
  SUPPORT: {
    path: '/support',
    isAuthOnly: true,
  },
  TOP_UP: {
    path: '/top-up',
    isGuestOnly: true,
  },
  NEW_SIM: {
    path: '/new-sim',
    isAuthOnly: true,
  },
  PLANS: {
    path: '/plans',
    isGuestOnly: true,
  },
  SIM: {
    ACTIVATE: {
      path: `/sim/activate`,
      isAuthOnly: true,
    },
  },
  TERMS_AND_CONDITIONS: {
    path: '/terms-and-conditions',
  },
  DEALER_TERMS_AND_CONDITIONS: {
    path: '/dealer-terms-and-conditions',
  },
  PRIVACY_POLICY: {
    path: '/privacy-policy',
  },
  PRISMIC: {
    DASHBOARD: {
      path: '/prismic/dashboard',
      allowedRoles: ['admin'],
      isAuthOnly: true,
    },
    DEPLOYMENT: {
      INDEX: {
        path: '/prismic/deployment',
        allowedEnvironments: ['local'],
        allowedRoles: ['admin'],
        isAuthOnly: true,
      },
      SLICES: {
        path: '/prismic/deployment/slice/:id',
        allowedEnvironments: ['local'],
        allowedRoles: ['admin'],
        isAuthOnly: true,
      },
      CUSTOM_TYPES: {
        path: '/prismic/deployment/custom-type/:id',
        allowedEnvironments: ['local'],
        allowedRoles: ['admin'],
        isAuthOnly: true,
      },
    },
  },
  PROFILE: {
    SPENDING_REPORT: {
      path: '/profile/spending-report',
      isAuthOnly: true,
    },
    SPECIAL_OFFERS: {
      path: '/profile/special-offers',
      isAuthOnly: true,
    },
    PERSONAL_DETAILS: {
      path: '/profile/personal-details',
      isAuthOnly: true,
    },
    INVOICES: {
      path: '/profile/invoices',
      isAuthOnly: true,
    },
    INVOICE: {
      path: '/profile/invoice/:id',
      isAuthOnly: true,
    },
    MODIFY_SUBSCRIPTION: {
      path: '/profile/modify-subscription',
      isAuthOnly: true,
    },
    SUBSCRIPTION_HISTORY: {
      path: '/profile/subscription-history/:subscriptionId',
      isAuthOnly: true,
    },
  },
};

export const EXTERNAL_ROUTES = {
  OCB: 'https://mmdbss.com/',
};
