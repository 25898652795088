import * as auth from './auth/api';
import * as clearCache from './clear-cache/api';
import * as resources from './resources/api';
import { prismic } from './prismic';

export const internal = {
  auth,
  prismic,
  clearCache,
  resources,
};
