/* eslint-disable no-console */
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { TFunction } from 'next-i18next';

import { DigitalApiError, ErrorHandlerParams } from './types';

export const handleErrors = async <
  TQueryFnData,
  Fn extends () => TQueryFnData | Promise<TQueryFnData>
>(
  func: Fn,
  t: TFunction
): Promise<TQueryFnData> => {
  try {
    return await func();
  } catch (err: unknown) {
    const { toast } = await import('react-toastify');
    const errorHandler = err as ErrorHandlerParams;
    const errorReason = getDigitalApiReason(err);
    const genericError = formatGenericError(err);
    const errorMsg = errorReason
      ? t(`backend:errors.${errorReason}`, { defaultValue: errorReason })
      : genericError;

    if (!errorHandler.hideToast) {
      toast.error(errorMsg);
    }

    if (errorHandler.showRawError) {
      throw err;
    } else {
      throw errorMsg;
    }
  }
};

export const getDigitalApiReason = (err: unknown): string | undefined => {
  const axiosError = err as Partial<AxiosError>;
  const digitalError = axiosError?.response?.data as Partial<DigitalApiError>;

  return Array.isArray(digitalError)
    ? digitalError[0]?.code
    : digitalError?.reason;
};

export const formatGenericError = (err: unknown): string => {
  const axiosError = err as Partial<AxiosError>;

  return typeof err === 'string'
    ? err
    : axiosError?.message || JSON.stringify(err);
};

export const parseHttpStatusCode = (err: unknown): StatusCodes | undefined =>
  (err as AxiosError)?.response?.status;

export const isValidationError = (err: unknown): boolean =>
  parseHttpStatusCode(err) === StatusCodes.PRECONDITION_FAILED;

export const isUnprocessableEntity = (err: unknown): boolean => {
  return parseHttpStatusCode(err) === StatusCodes.UNPROCESSABLE_ENTITY;
};

export const captureError = (
  prefix: string,
  err: unknown,
  shouldFormat = true
): void => {
  console.error(prefix);
  console.error('............');
  console.error(shouldFormat ? formatGenericError(err) : err);
};
