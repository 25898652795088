import {
  PostLoginDto,
  PostLoginRo,
  PostResetPasswordDto,
  PostResetPasswordRo,
  PostSignupDto,
  PostSignupRo,
} from '@api/userfront/auth/types';
import Userfront from '@userfront/nextjs';

export const postLogin = async (dto: PostLoginDto): Promise<PostLoginRo> => {
  const res: PostLoginRo = await Userfront.login({
    redirect: false,
    method: 'password',
    ...dto,
  });

  return res;
};

export const deleteLogout = async (): Promise<void> => {
  await Userfront.logout({ redirect: false });
};

export const postSignup = async (dto: PostSignupDto): Promise<PostSignupRo> => {
  const res: PostSignupRo = await Userfront.signup({
    ...dto,
    redirect: false,
    method: 'password',
  });

  return res;
};

export const postResetPassword = async (
  dto: PostResetPasswordDto
): Promise<PostResetPasswordRo> => {
  const res: PostResetPasswordRo = await Userfront.resetPassword(dto);

  return res;
};
