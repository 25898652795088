import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import { GetTicketCategoryRo } from './types';

export const getTicketCategory = async (
  tenant: Tenant
): Promise<GetTicketCategoryRo> => {
  const { data } = await client.get<GetTicketCategoryRo>(
    API_URLS.ticketCategory.index,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return data;
};
