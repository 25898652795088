import { QueryClient } from '@tanstack/react-query';

import { TenantName } from '@lib/tenants/types';

import { ReportFilterParams } from './types';

export const CACHE_KEYS = {
  specialOffers: (subscriptionId: string): string[] => [
    'special-offers',
    subscriptionId,
  ],
  reportFilter: (params: ReportFilterParams): [string, ReportFilterParams] => [
    'spending-report-filter',
    params,
  ],
  cmsPackage: (subscriptionId: string): string[] => [
    'cms-parent-product',
    subscriptionId,
  ],
  subscriptions: 'subscriptions',
  subscription: (subscriptionId: string): string[] => [
    'subscription',
    subscriptionId,
  ],
  allSlices: 'all-slices',
  allCustomTypes: 'all-custom-types',
  singleCustomType: (routeId: string): string[] => ['custom-type', routeId],
  singleSlice: (routeId: string): string[] => ['slice', routeId],
  simStatus: 'simStatus',
  classifiers: 'classifiers',
  personalDetails: 'personal-details',
  invoices: 'invoices',
  invoice: (invoiceId: string): string[] => ['invoice', invoiceId],
  accountUpcomingInvoiceAmount: (accountId: string) => [
    'account-upcoming-invoice-amount',
    accountId,
  ],
  customerPayments: (accountId: string, startRow: string, endRow: string) => [
    'customer-payments',
    accountId,
    startRow,
    endRow,
  ],
  customerOrders: (startRow: string, endRow: string) => [
    'customer-orders',
    startRow,
    endRow,
  ],
  accountBalance: 'account-balance',
  addonsBalance: 'addons-balance',
  ticketCategory: (subscriptionId: string): string[] => [
    'ticket-category',
    subscriptionId,
  ],
  tickets: 'tickets',
  homePageSlice: (tenantName: TenantName): string[] => [
    'home-page-slice',
    tenantName,
  ],
  tenantPaymentCapabilities: 'tenant-payment-capabilities',
  ratesFile: 'rates-file',
  mobilesRatesFile: 'mobile-rates-file',
  roamingRatesFile: 'roaming-rates-file',
  tenantById: 'tenant-by-id',
  order: (orderId: string) => ['order', orderId],
  parentProducts: (
    offerCategoryId?: string,
    page?: string,
    pageSize?: string,
  ) => ['parent-products', offerCategoryId, page, pageSize],
  matchingOffers: (subscriptionId?: string) => [
    'matching-offers',
    subscriptionId,
  ],
} as const;

export const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
