import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import { PostTicketDto, PostTicketRo, Ticket } from './types';

export const postTicket = async (
  dto: PostTicketDto,
  tenant: Tenant
): Promise<PostTicketRo> => {
  const { data } = await client.post<PostTicketRo>(
    API_URLS.ticket.submit,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return data;
};

export const getTickets = async (tenant: Tenant): Promise<Ticket[]> => {
  const { data } = await client.get<Ticket[]>(API_URLS.ticket.list, {
    baseURL: getBaseUrl(tenant),
  });

  return data;
};
