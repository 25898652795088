import { getEnv } from '@lib/env/getEnv';
import { AugmentedRequest } from '@lib/fetch/types';
import { Tenant } from '@lib/tenants/types';
import Prismic from '@prismicio/client';

export const getPreviewToken = (
  cookies: AugmentedRequest['cookies'],
  tenant: Tenant
): string | undefined => {
  const env = getEnv();
  const cmsSubdomain = tenant.config.setup.cmsSubdomain[env.client.appEnv];

  const previewToken =
    cmsSubdomain &&
    cookies[Prismic.previewCookie] &&
    JSON.parse(cookies[Prismic.previewCookie])?.[`${cmsSubdomain}.prismic.io`]
      ?.preview;

  return previewToken;
};
