import { FC, Fragment } from 'react';

import { ChildrenRenderer } from './ChildrenRenderer';
import { IterableChildren } from './types';

interface Props<T> {
  children: IterableChildren;
  Provider: FC<T>;
  condition: boolean;
  providerProps?: Omit<T, 'children'>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const ConditionalProvider = <T extends {}>(
  props: Props<T>
): JSX.Element => {
  const { children, Provider, providerProps, condition } = props;

  if (!condition) {
    return <ChildrenRenderer Wrapper={Fragment}>{children}</ChildrenRenderer>;
  }

  return (
    <Provider {...(providerProps as T)}>
      {<ChildrenRenderer Wrapper={Fragment}>{children}</ChildrenRenderer>}
    </Provider>
  );
};
