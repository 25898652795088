import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import {
  PostChangeSubscriptionUserPasswordDto,
  PostCreateSubscriptionUserDto,
  PostForgotPasswordDto,
  PostForgotPasswordRo,
  PostMsisdnVerificationCodeDto,
  SuccessResponse,
} from './types';

export const postForgotPassword = async (
  dto: PostForgotPasswordDto,
  tenant: Tenant
): Promise<PostForgotPasswordRo> => {
  const res = await client.post<PostForgotPasswordRo>(
    API_URLS.auth.forgotPassword,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return res.data;
};

export const sendMsisdnVerificationCode = async (
  dto: PostMsisdnVerificationCodeDto,
  tenant: Tenant
): Promise<SuccessResponse> => {
  const res = await client.post<SuccessResponse>(
    API_URLS.auth.sendVerificationCodeToMsisdn,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return res.data;
};

export const createSubscriptionUser = async (
  dto: PostCreateSubscriptionUserDto,
  tenant: Tenant
): Promise<SuccessResponse> => {
  const res = await client.post<SuccessResponse>(
    API_URLS.auth.creteSubscriptionUser,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return res.data;
};

export const changeSubscriptionUserPassword = async (
  dto: PostChangeSubscriptionUserPasswordDto,
  tenant: Tenant
): Promise<SuccessResponse> => {
  const res = await client.post<SuccessResponse>(
    API_URLS.auth.changeSubscriptionUserPassword,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return res.data;
};
