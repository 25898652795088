import { Tenant } from '@lib/tenants/types';

import { client } from '../client';
import { getBaseUrl } from '../config';
import { API_URLS } from '../constants';
import {
  SimActivateDto,
  SimActivateRo,
  SimValidateDto,
  SimValidateRo,
} from './types';

export const getSimStatus = async (tenant: Tenant): Promise<unknown[]> => {
  const { data } = await client.get<unknown[]>(API_URLS.sim.status, {
    baseURL: getBaseUrl(tenant),
  });

  return data;
};

export const activateSim = async (
  dto: SimActivateDto,
  tenant: Tenant
): Promise<SimActivateRo> => {
  const { data } = await client.post<SimActivateRo>(
    API_URLS.sim.activate,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return data;
};

export const validateSim = async (
  dto: SimValidateDto,
  tenant: Tenant
): Promise<SimValidateRo> => {
  const { data } = await client.post<SimValidateRo>(
    API_URLS.sim.validate,
    dto,
    {
      baseURL: getBaseUrl(tenant),
    }
  );

  return data;
};
