import { APP_ROUTES } from './constants';
import { withoutQueryParams } from './queryParams';
import { replaceRouteParams } from './routeParams';
import { AppRoute, AppRoutes, DynamicRoutes, QueryParams } from './types';

export const isRouteActive = (
  currPath: string,
  matchPath?: string
): boolean => {
  if (!matchPath) {
    return false;
  }

  const currPathWithoutQueryParams = withoutQueryParams(currPath);
  const matchPathWithoutQueryParams = withoutQueryParams(matchPath);

  if (
    matchPathWithoutQueryParams !== currPathWithoutQueryParams &&
    matchPathWithoutQueryParams !== APP_ROUTES.HOME.path
  ) {
    return matchPathWithoutQueryParams.endsWith(currPathWithoutQueryParams);
  }

  return matchPathWithoutQueryParams === currPathWithoutQueryParams;
};

const isRoute = (route: AppRoute | DynamicRoutes): route is AppRoute => {
  return !!route.path;
};

export const findRoute = <T extends keyof AppRoute>(
  routes: DynamicRoutes | AppRoutes,
  matchKey: T,
  asPath: string,
  query: QueryParams
): AppRoute | null => {
  const values = Object.values(routes);
  let foundRoute: AppRoute | null = null;

  values.forEach((value) => {
    if (isRoute(value)) {
      const hasQueryParams = Object.values(query).length;
      const isExactMatch = value[matchKey] === asPath;

      if (isExactMatch) {
        foundRoute = value;
      } else if (hasQueryParams) {
        const fullPath = withoutQueryParams(asPath);
        const hasDynamicMatch =
          replaceRouteParams(value[matchKey] as string, query) === fullPath;

        if (hasDynamicMatch) {
          foundRoute = value;
        }
      }
    } else {
      const match = findRoute(value, matchKey, asPath, query);

      if (match) {
        foundRoute = match;
      }
    }
  });

  return foundRoute;
};
