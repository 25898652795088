import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

import { api } from '@api/index';
import {
  GetSubscriptionsRo,
  Subscription,
} from '@api/ocb-digital/subscription/types';
import { useRouter } from 'next/router';

import { useAuth } from '../auth/AuthContext';
import { CACHE_KEYS } from '../fetch/constants';
import { useApiQuery } from '../fetch/useApiQuery';
import { SESSION_STORAGE } from '../storage/constants';
import { useStorage } from '../storage/useStorage';
import { useTenantProps } from '../tenants/TenantPropsContext';

export const SubscriptionsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();
  const { tenant } = useTenantProps();
  const { data, isLoading, refetch, isError, isFetching, isFetched } =
    useApiQuery(fetchSubscriptions, { queryKey: [CACHE_KEYS.subscriptions] });
  const router = useRouter();
  const previousPathnameRef = useRef<string | null>(null);

  const [storedSubscriptionId, setStoredSubscriptionId] = useStorage<
    string | null
  >('session', SESSION_STORAGE.SELECTED_SUBSCRIPTION_ID, null);

  const {
    data: subscription,
    isLoading: isLoadingSubscription,
    isError: isErrorSubscription,
    isFetching: isFetchingSubscription,
    refetch: refetchSubscription,
  } = useApiQuery(fetchSubscription, {
    queryKey: [storedSubscriptionId],
    enabled:
      !!storedSubscriptionId &&
      storedSubscriptionId !== 'UNAVAILABLE' &&
      isAuthenticated,
  });

  const handleSelectSubscription = useCallback(
    (selectedId: string) => {
      setStoredSubscriptionId(selectedId);
    },
    [setStoredSubscriptionId],
  );

  const handleSubscriptionFetchSuccess = useCallback(
    (subscriptions?: GetSubscriptionsRo | null) => {
      if (!subscriptions || !subscriptions.length) {
        return;
      }

      const storedSubscriptionExists = subscriptions.some(
        ({ id }) => id === storedSubscriptionId,
      );

      if (!storedSubscriptionExists) {
        handleSelectSubscription(subscriptions[0].id);
      }
    },
    [handleSelectSubscription, storedSubscriptionId],
  );

  useEffect(() => {
    handleSubscriptionFetchSuccess(data);
  }, [data, handleSubscriptionFetchSuccess, isFetched]);

  useEffect(() => {
    refetch();
  }, [isAuthenticated, refetch]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const currentPathname = new URL(url, window.location.origin).pathname;
      const previousPathname = previousPathnameRef.current;

      if (previousPathname !== currentPathname) {
        refetchSubscription();
      }

      previousPathnameRef.current = currentPathname;
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [refetchSubscription, router.events]);

  return (
    <SubscriptionsContext.Provider
      value={{
        subscriptions: data ?? [],
        isLoading: isLoading || isLoadingSubscription,
        isError: isError || isErrorSubscription,
        isFetching: isFetching || isFetchingSubscription,
        selectSubscription: handleSelectSubscription,
        selectedSubscription: subscription ?? null,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );

  function fetchSubscriptions(): Promise<GetSubscriptionsRo> | null {
    if (isAuthenticated) {
      return api.ocbDigital.subscription.getSubscriptions(tenant);
    }

    return null;
  }

  async function fetchSubscription() {
    if (
      !storedSubscriptionId ||
      storedSubscriptionId === 'UNAVAILABLE' ||
      !isAuthenticated
    ) {
      return null;
    }
    return api.ocbDigital.subscription.getSubscription(
      { subscriptionId: storedSubscriptionId },
      tenant,
    );
  }
};

export interface SubscriptionsContextProps {
  subscriptions: Subscription[];
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  selectSubscription: (id: string) => void;
  selectedSubscription: Subscription | null;
}

const SubscriptionsContext = createContext<
  SubscriptionsContextProps | undefined
>(undefined);

export const useSubscriptions = (): SubscriptionsContextProps => {
  const context = useContext(SubscriptionsContext);

  if (context === undefined) {
    throw new Error(
      'useSubscriptions must be used within an SubscriptionsProvider',
    );
  }

  return context;
};
