import { ServerResponse } from 'http';

import { serialize } from 'cookie';
import Cookies from 'js-cookie';

import { getEnv } from '@lib/env/getEnv';
import { COOKIE_STORAGE } from '@lib/storage/constants';
import { TENANT_CONFIG } from '@lib/tenants/config/constants';
import { TenantName } from '@lib/tenants/types';

export const makeAccessToken = (id: string): string => `access.${id}`;

export const makeIdToken = (id: string): string => `id.${id}`;

export const makeRefreshToken = (id: string): string => `refresh.${id}`;

export const removeAuthTokens = (id: string, res?: ServerResponse): void => {
  const accessToken = makeAccessToken(id);
  const idToken = makeIdToken(id);
  const refreshToken = makeRefreshToken(id);

  if (res) {
    res.setHeader('Set-Cookie', [
      removeServerSideCookie(accessToken),
      removeServerSideCookie(idToken),
      removeServerSideCookie(refreshToken),
      removeServerSideCookie(COOKIE_STORAGE.ROLE),
    ]);
  } else {
    removeClientSideCookie(accessToken);
    removeClientSideCookie(idToken);
    removeClientSideCookie(refreshToken);
    removeServerSideCookie(COOKIE_STORAGE.ROLE);
  }
};

const removeServerSideCookie = (name: string): string => {
  const env = getEnv();

  return serialize(name, '', {
    httpOnly: true,
    secure: env.server.nodeEnv !== 'development',
    expires: new Date(0),
    sameSite: 'strict',
    path: '/',
  });
};

const removeClientSideCookie = (name: string): void => {
  Cookies.remove(name);
  Cookies.remove(name, { secure: true, sameSite: 'Lax' });
  Cookies.remove(name, { secure: true, sameSite: 'None' });
  Cookies.remove(name, { secure: false, sameSite: 'Lax' });
  Cookies.remove(name, { secure: false, sameSite: 'None' });
};

export const getAccessToken = (
  tenantName: TenantName,
  cookies: Record<string, string>
): string | undefined => {
  const env = getEnv();
  const tenant = tenantName ? TENANT_CONFIG[tenantName] : undefined;
  const authTenantId = tenant?.config.setup.authTenantId[env.client.appEnv];
  const accessToken = authTenantId && cookies[makeAccessToken(authTenantId)];

  return accessToken;
};
