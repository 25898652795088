import { client } from '@api/prismic/client';
import { AvailableLocales } from '@lib/route/types';
import { Tenant } from '@lib/tenants/types';
import Prismic from '@prismicio/client';

import { GetBlogPageRo, GetRootPagesRo } from './types';

export const getAllRootPages = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<GetRootPagesRo> => {
  const res = (await client(tenant).query(
    Prismic.Predicates.at('document.type', 'page'),
    {
      lang: appLocale,
      ...(previewRef && { ref: previewRef }),
    },
  )) as GetRootPagesRo;

  return res;
};

export const getAllBlogPages = async (
  tenant: Tenant,
  appLocale: AvailableLocales,
  previewRef?: string,
): Promise<GetBlogPageRo> => {
  const res = (await client(tenant).query(
    Prismic.Predicates.at('document.type', 'blog'),
    {
      lang: appLocale,
      ...(previewRef && { ref: previewRef }),
    },
  )) as GetBlogPageRo;

  return res;
};
