import { ColorPaletteDoc } from '@api/prismic/components/types';
import { Tenant, TenantTheme } from '@lib/tenants/types';
import { ThemeOptions } from '@mui/material';
import { ComponentsOverrides, createTheme, Theme } from '@mui/material/styles';
import { createHeadingStyle, deepMerge } from './utils';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const customMuiTheme = (tenantTheme?: TenantTheme): Theme => {
  const theme = setup(tenantTheme);

  return createTheme(theme);
};

export const getTenantThemes = (tenantTheme?: TenantTheme): Theme => {
  if (tenantTheme) {
    return customMuiTheme(tenantTheme);
  }

  return customMuiTheme();
};

export const mapColorPaletteIntoTheme = (
  colorPalette: ColorPaletteDoc['data'] | null,
  tenant?: Tenant,
): TenantTheme => {
  return {
    ...tenant?.theme,
    material: {
      palette: {
        primary: {
          light: colorPalette?.primaryLight ?? '#5885AF',
          main: colorPalette?.primaryMain ?? '#41729F',
          dark: colorPalette?.primaryDark ?? '#274472',
        },
        secondary: {
          light: colorPalette?.secondaryLight ?? '#60C09C',
          main: colorPalette?.secondaryMain ?? '#3CA67E',
          dark: colorPalette?.secondaryDark ?? '#107A52',
        },
      },
    },
    specifics: {
      ...(colorPalette?.userCard?.[0]?.useCustomColors && {
        userCard: {
          backgroundColor: colorPalette?.userCard?.[0].backgroundColor
            ? colorPalette?.[colorPalette?.userCard?.[0].backgroundColor]
            : undefined,
          borderColor: colorPalette?.userCard?.[0].borderColor
            ? colorPalette?.[colorPalette?.userCard?.[0].borderColor]
            : undefined,
          textColor: colorPalette?.userCard?.[0].textColor
            ? colorPalette?.[colorPalette?.userCard?.[0].textColor]
            : undefined,
        },
      }),
      ...(colorPalette?.authUserGradientBanner?.[0]?.useCustomColors && {
        authUserGradientBanner: {
          firstGradientColor: colorPalette?.authUserGradientBanner?.[0]
            .firstGradientColor
            ? colorPalette?.[
                colorPalette?.authUserGradientBanner?.[0].firstGradientColor
              ]
            : undefined,
          secondGradientColor: colorPalette?.authUserGradientBanner?.[0]
            .secondGradientColor
            ? colorPalette?.[
                colorPalette?.authUserGradientBanner?.[0].secondGradientColor
              ]
            : undefined,
        },
      }),
      ...(colorPalette?.drawer?.[0]?.useCustomColors && {
        drawer: {
          activeColor: colorPalette?.drawer?.[0].activeColor
            ? colorPalette?.[colorPalette?.drawer?.[0].activeColor]
            : undefined,
          backgroundColor: colorPalette?.drawer?.[0].backgroundColor
            ? colorPalette?.[colorPalette?.drawer?.[0].backgroundColor]
            : undefined,
          color: colorPalette?.drawer?.[0].color
            ? colorPalette?.[colorPalette?.drawer?.[0].color]
            : undefined,
        },
      }),
      ...(colorPalette?.footer?.[0]?.useCustomColors && {
        footer: {
          activeColor: colorPalette?.footer?.[0].activeColor
            ? colorPalette?.[colorPalette?.footer?.[0].activeColor]
            : undefined,
        },
      }),
      header: {
        ...(colorPalette?.headerBarDropdown?.[0]?.useCustomColors && {
          barDropdown: {
            activeColor: colorPalette?.headerBarDropdown?.[0].activeColor
              ? colorPalette?.[colorPalette?.headerBarDropdown?.[0].activeColor]
              : undefined,
            backgroundColor: colorPalette?.headerBarDropdown?.[0]
              .backgroundColor
              ? colorPalette?.[
                  colorPalette?.headerBarDropdown?.[0].backgroundColor
                ]
              : undefined,
          },
        }),
        ...(colorPalette?.headerBottomBar?.[0]?.useCustomColors && {
          bottomBar: {
            activeColor: colorPalette?.headerBottomBar?.[0].activeColor
              ? colorPalette?.[colorPalette?.headerBottomBar?.[0].activeColor]
              : undefined,
            backgroundColor: colorPalette?.headerBottomBar?.[0].backgroundColor
              ? colorPalette?.[
                  colorPalette?.headerBottomBar?.[0].backgroundColor
                ]
              : undefined,
            color: colorPalette?.headerBottomBar?.[0].color
              ? colorPalette?.[colorPalette?.headerBottomBar?.[0].color]
              : undefined,
          },
        }),
        ...(colorPalette?.headerTopBar?.[0]?.useCustomColors && {
          topBar: {
            dividerColor: colorPalette?.headerTopBar?.[0].dividerColor
              ? colorPalette?.[colorPalette?.headerTopBar?.[0].dividerColor]
              : undefined,
            backgroundColor: colorPalette?.headerTopBar?.[0].backgroundColor
              ? colorPalette?.[colorPalette?.headerTopBar?.[0].backgroundColor]
              : undefined,
          },
        }),
      },
      ...(colorPalette?.authHomePageCard?.[0]?.useCustomColors && {
        authHomePageCard: {
          backgroundColor: colorPalette?.authHomePageCard?.[0].backgroundColor
            ? colorPalette?.[
                colorPalette?.authHomePageCard?.[0].backgroundColor
              ]
            : undefined,
        },
      }),
      ...(colorPalette?.authTicketTable?.[0]?.useCustomColors && {
        authTicketTable: {
          headerBackgroundColor: colorPalette?.authTicketTable?.[0]
            .headerBackgroundColor
            ? colorPalette?.[
                colorPalette?.authTicketTable?.[0].headerBackgroundColor
              ]
            : undefined,
          tableBodyCellBorderColor: colorPalette?.authTicketTable?.[0]
            .tableBodyCellBorderColor
            ? colorPalette?.[
                colorPalette?.authTicketTable?.[0].tableBodyCellBorderColor
              ]
            : undefined,
        },
      }),
      ...(colorPalette?.offerCard?.[0]?.useCustomColors && {
        offerCard: {
          backgroundColor: colorPalette?.offerCard?.[0].backgroundColor
            ? colorPalette?.[colorPalette?.offerCard?.[0].backgroundColor]
            : undefined,
          labelColor: colorPalette?.offerCard?.[0].labelColor
            ? colorPalette?.[colorPalette?.offerCard?.[0].labelColor]
            : undefined,
          priceBackgroundColor: colorPalette?.offerCard?.[0]
            .priceBackgroundColor
            ? colorPalette?.[colorPalette?.offerCard?.[0].priceBackgroundColor]
            : undefined,
          priceColor: colorPalette?.offerCard?.[0].priceColor
            ? colorPalette?.[colorPalette?.offerCard?.[0].priceColor]
            : undefined,
          valueColor: colorPalette?.offerCard?.[0].valueColor
            ? colorPalette?.[colorPalette?.offerCard?.[0].valueColor]
            : undefined,
        },
      }),
      ...(colorPalette?.stepper?.[0]?.useCustomColors && {
        stepper: {
          backgroundColor: colorPalette?.stepper?.[0].backgroundColor
            ? colorPalette?.[colorPalette?.stepper?.[0].backgroundColor]
            : undefined,
          borderColor: colorPalette?.stepper?.[0].borderColor
            ? colorPalette?.[colorPalette?.stepper?.[0].borderColor]
            : undefined,
          connectorColor: colorPalette?.stepper?.[0].connectorColor
            ? colorPalette?.[colorPalette?.stepper?.[0].connectorColor]
            : undefined,
          finishedActiveBackgroundColor: colorPalette?.stepper?.[0]
            .finishedActiveBackgroundColor
            ? colorPalette?.[
                colorPalette?.stepper?.[0].finishedActiveBackgroundColor
              ]
            : undefined,
          finishedActiveBorderColor: colorPalette?.stepper?.[0]
            .finishedActiveBorderColor
            ? colorPalette?.[
                colorPalette?.stepper?.[0].finishedActiveBorderColor
              ]
            : undefined,
          textColor: colorPalette?.stepper?.[0].textColor
            ? colorPalette?.[colorPalette?.stepper?.[0].textColor]
            : undefined,
        },
      }),
    },
  };
};

export const fontWeightSizes: TypographyOptions = {
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
};

export const setup = (tenantTheme: TenantTheme = {}): ThemeOptions => {
  const { palette, components, ...rest } =
    (tenantTheme?.material as ThemeOptions) ?? {};
  const defaultPalette = {
    primary: {
      light: '#5cf7ce',
      main: '#32b391',
      dark: '#117359',
    },
    secondary: {
      light: '#e33371',
      main: '#dc004e',
      dark: '#9a0036',
    },
    light: {
      main: '#FFFFFF',
      dark: '#dedede',
    },
    dark: {
      main: '#000000',
    },
    success: {
      light: '#8df960',
      main: '#0FC246',
      dark: '#469d01',
    },
    info: {
      light: '#7fdce8',
      main: '#41c2fd',
    },
    warn: {
      main: '#f57c00',
    },
    error: {
      light: '#ff6953',
      main: '#f44336',
      dark: '#e50601',
    },
    text: {
      primary: '#3a3a3c',
      secondary: '#606060',
      disabled: '#A8A8A8',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
  };
  const fallBackFontFamilies = '"Roboto", "Helvetica", "Arial", sans-serif';
  const fontFamily = tenantTheme?.mainFontFamily?.name
    ? `"${tenantTheme?.mainFontFamily?.name}", ${fallBackFontFamilies}`
    : fallBackFontFamilies;
  const fontFamiliesToImport = tenantTheme?.mainFontFamily?.fontFiles
    ? tenantTheme?.mainFontFamily?.fontFiles
    : [];
  const mappedFontFamilies = fontFamiliesToImport?.map((fontFamily) => {
    return {
      fontFamily: tenantTheme?.mainFontFamily?.name,
      fontStyle: fontFamily.fontStyle ?? 'normal',
      fontWeight: fontFamily.weight,
      src: `url('${fontFamily.path}') format('${fontFamily.format}')`,
    };
  });
  const fontStyles: TypographyOptions = {
    fontFamily: fontFamily,
    ...fontWeightSizes,
  };
  const mergedPalette = deepMerge(defaultPalette, palette) ?? defaultPalette;
  const defaultTypographyStyles = {
    h1: createHeadingStyle(52, 40, 32, 1.1, fontWeightSizes.fontWeightMedium),
    h2: createHeadingStyle(44, 36, 28, 1.1),
    h3: createHeadingStyle(36, 30, 24, 1.1),
    h4: createHeadingStyle(30, 24, 20, 1.2),
    h5: createHeadingStyle(26, 22, 18, 1.2),
    h6: createHeadingStyle(22, 18, 16, 1.2),
  };
  const typographyStyles = Object.keys(defaultTypographyStyles).reduce(
    (acc, heading) => {
      acc[heading] = {
        ...defaultTypographyStyles[heading],
        ...(tenantTheme?.typographyStyles?.[heading] || {}),
      };
      return acc;
    },
    {},
  );
  const defaultComponents: ThemeOptions['components'] = {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        a: {
          color: 'inherit',
        },
        body: {
          height: '100%',
          overflow: 'hidden',
        },
        ...typographyStyles,
        ...tenantTheme?.customStyles,
        '#__next': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        },
        /** React-toastify default background overrides */
        '.Toastify__toast': {
          color: `${mergedPalette.background.default} !important`,
          svg: {
            fill: `${mergedPalette.background.default} !important`,
          },
        },
        '.Toastify__toast--success': {
          background: `${mergedPalette.success.main} !important`,
        },
        '.Toastify__toast--error': {
          background: `${mergedPalette.error.main} !important`,
        },
        '.Toastify__toast--info': {
          background: `${mergedPalette.info.main} !important`,
        },
        '.Toastify__toast--warning': {
          background: `${mergedPalette.warn.main} !important`,
        },
        ...(!!mappedFontFamilies.length &&
          mappedFontFamilies.map((fontFamily) => ({
            '@font-face': fontFamily,
          }))),
      } as ComponentsOverrides<Theme>['MuiCssBaseline'],
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: mergedPalette.primary.main,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingBottom: 20,

          '&:last-child': {
            paddingBottom: 20,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: mergedPalette.light.main,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        ...typographyStyles,
      },
    },
  };
  const mergedComponents =
    deepMerge(defaultComponents, components) ?? defaultComponents;
  const theme: ThemeOptions = {
    palette: mergedPalette,
    typography: fontStyles,
    components: mergedComponents,
    ...rest,
  };

  return theme;
};
