import { Tenant } from '@lib/tenants/types';

export const TEST_TENANT: Tenant = {
  config: {
    setup: {
      id: {
        local: 'test',
        demo: 'test',
        qa: 'test',
        prod: 'test',
        staging: 'test',
      },
      ryftPublicKey: {
        local:
          'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        demo: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        qa: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        prod: 'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
        staging:
          'pk_sandbox_wCLdq9JLAk1H+gqjotrJ4Thr6VaUI3+2CTpruusTyVnFeE54vPMiF9B+hYVa0kyy',
      },
      paypalPublicKey: {
        local: 'test',
        demo: 'test',
        qa: 'test',
        prod: 'test',
        staging: 'test',
      },
      name: 'base',
      cmsSubdomain: {
        local: 'test',
        demo: 'test',
        qa: 'test',
        prod: 'test',
        staging: 'test',
      },
      authTenantId: {
        local: 'test',
        demo: 'test',
        qa: 'test',
        prod: 'test',
        staging: 'test',
      },
      ocbDigitalBaseUrl: {
        demo: 'test',
        qa: 'test',
        prod: 'test',
        staging: 'test',
      },
    },
  },
  cmsRoutes: [],
  theme: {
    specifics: {},
    material: {},
  },
  seo: {
    displayName: 'test',
  },
};

export const TOAST_TEST_ID = 'toast-error-test-id';

export const FORM_ERROR_TEST_ID = 'form-error-test-id';
